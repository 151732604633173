/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Layout, StaticSEO } from '@core';
import { LocaleGuard, WorkshopPage } from '@composite';

export default () => (
  <LocaleGuard enabledLocales={['de']} redirectTo="/de/workshops">
    <Layout>
      <StaticSEO pageId="finnoscore" />
      <WorkshopPage />
    </Layout>
  </LocaleGuard>
);
